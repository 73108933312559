import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Cx from "../../../style/custom.module.scss";
/* icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoLockClosedOutline, IoMailOutline } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
import { LiaIndustrySolid } from "react-icons/lia";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
/* ======= */
import { loginUser, loginUserGoogle, registerUser } from "../../../redux/slice/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../utils/Toster";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Logo from "../../../assets/images/logos/gray-logos/reservedlogo.svg";
import axios from "axios";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../../firebase";
import Googlelogo2 from "../../../assets/images/logos/gray-logos/google2.png";
import Applelogo2 from "../../../assets/images/logos/gray-logos/apple2.png";
const Signuplogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("first");

  const handleSelect = (tab) => {
    setActiveTab(tab);
  };
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    formState: { errors: errorsLogin },
  } = useForm();

  const password = watch("password");

  const onsetWelcomeQuesResponceHandler = async (userId) => {
    try {
      const resData = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${userId}`);
      console.log(resData, "resData?.data?.annual_turnover");
      return resData?.data;
    } catch (error) {
      console.log("Error fetching question response:", error);
    }
  };

  const onSubmitLogin = async (data) => {
    try {
      const actionResult = await dispatch(loginUser(data));
      if (loginUser.fulfilled.match(actionResult)) {
        localStorage.setItem("userId", actionResult?.payload?.data?.id);
        localStorage.setItem("email", actionResult?.payload?.data?.email);
        const userId = actionResult?.payload?.data?.id;
        const ApiData = await onsetWelcomeQuesResponceHandler(userId);
        if (ApiData) {
          if (
            (actionResult?.payload?.data?.is_taxrelief &&
              actionResult?.payload?.data?.is_business) ||
            (!actionResult?.payload?.data?.is_taxrelief &&
              !actionResult?.payload?.data?.is_business)
          ) {
            navigate("/lookingfor");
          } else if (actionResult?.payload?.data?.is_taxrelief) {
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
            localStorage.setItem("UserType", "is_taxrelief");
            navigate("/dashboard");
          } else if (actionResult?.payload?.data?.is_business && ApiData?.annual_turnover == 0.0) {
            localStorage.setItem("UserType", "is_business");
            navigate("/welcome-analysis");
          } else if (actionResult?.payload?.data?.is_business && ApiData?.annual_turnover !== 0.0) {
            localStorage.setItem("UserType", "is_business");
            navigate("/dashboard-business");
          } else {
            navigate("/lookingfor");
          }
          showSuccessToast("Sign In Successfully");
        } else {
          showErrorToast(actionResult?.payload);
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const actionResult = await dispatch(registerUser(data));
      if (registerUser.fulfilled.match(actionResult)) {
        showSuccessToast("Successfully registered");
        if (actionResult?.payload?.id !== null && actionResult?.payload?.id !== undefined) {
          localStorage.setItem("userId", actionResult?.payload?.id);
          localStorage.setItem("email", actionResult?.payload?.email);
          navigate("/Lookingfor");
          window.location.reload();
        }
      } else {
        showErrorToast(actionResult?.payload?.email[0]);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showErrorToast("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(credential, token);
        const user = result?.user;
        console.log(user);

        if (user) {
          const data = {
            email: user?.email,
            mobile_no: "",
            full_name: user?.displayName,
            company_name: "",
            industry: "",
            sso_id: user?.uid,
          };
          localStorage.setItem("data", JSON.stringify(data));
          navigate("/signUp");
        }
      })
      .catch((error) => {
        console.log(error, ">>>>>>>>>>");
        const errorCode = error?.code;
        console.log(errorCode);
        const errorMessage = error?.message;
        console.log(errorMessage);
        const email = error?.customData?.email;
        console.log(email);
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(credential);
        console.log(email);
      });
  };

  const handleLoginGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      console.log(credential, token);
      const user = result?.user;
      const userData = { sso_id: user?.uid };
      const actionResult = await dispatch(loginUserGoogle(userData));

      if (loginUserGoogle.fulfilled.match(actionResult)) {
        const { id, email, is_taxrelief, is_business } = actionResult.payload.data;
        localStorage.setItem("userId", id);
        localStorage.setItem("email", email);
        const ApiData = await onsetWelcomeQuesResponceHandler(id);
        const { annual_turnover } = ApiData || {};

        // Determine navigation based on user type and business conditions
        const navigateUser = () => {
          if (is_taxrelief && is_business) {
            navigate("/lookingfor");
          } else if (is_taxrelief) {
            localStorage.setItem("UserType", "is_taxrelief");
            navigate("/dashboard");
          } else if (is_business) {
            localStorage.setItem("UserType", "is_business");
            if (annual_turnover === 0.0) {
              navigate("/welcome-analysis");
            } else {
              navigate("/dashboard-business");
            }
          } else {
            navigate("/lookingfor");
          }
        };
        if (ApiData) {
          navigateUser();
          showSuccessToast("Sign In Successfully");
        } else {
          showErrorToast(actionResult.payload);
        }
      }
    } catch (error) {
      console.error("Login Error:", error);
      const { code, message, customData } = error;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log({ code, message, email: customData?.email, credential });
    }
  };

  const handelAppleSingup = async () => {
    console.log("oooooooooooooooooo");
  };

  const handelAppleSingin = async () => {
    console.log("LLLLLLLLLLLLl");
  };

  return (
    <>
      <div className="container-fluid mx-0 px-0">
        <section className={Cx.sectionsignuplogin}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                <div className={Cx.logosignup}>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className={Cx.signuplogoimg}>
                      <img src={Logo} alt="logo" />
                    </div>
                  </div>
                  <p>Your journey. Our expertise. One destination: AG Finance Hub. Best wishes,</p>
                </div>
                <div className={Cx.containermid}>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className={Cx.logincreatearea}>
                      <div className="row mx-0 px-0">
                        <div className="col-6 mx-0 px-0">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              // className={Cx.btnsignin}
                              className={`${Cx.btnsignin}`}
                              style={
                                activeTab === "first"
                                  ? {
                                      color: "#fff",
                                      backgroundColor: "#976f1d",
                                    }
                                  : {}
                              }
                              onClick={() => handleSelect("first")}
                            >
                              Sign In
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                        <div className="col-6 mx-0 px-0">
                          <Nav.Link
                            eventKey="second"
                            className={Cx.btnsignincreate}
                            style={
                              activeTab === "second"
                                ? {
                                    color: "#fff",
                                    backgroundColor: "#976f1d",
                                  }
                                : {}
                            }
                            onClick={() => handleSelect("second")}
                          >
                            Create Account
                          </Nav.Link>
                        </div>
                      </div>
                    </div>

                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className={Cx.signuploginform}>
                          <form
                            onSubmit={handleSubmitLogin(onSubmitLogin)}
                            className={Cx.logingform}
                          >
                            <div className={Cx.signtop}>
                              <div className={Cx.introText}>
                                <h2>Login</h2>
                              </div>
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <IoMailOutline />
                                </div>

                                <input
                                  type="email"
                                  id="email"
                                  placeholder="Email"
                                  {...registerLogin("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value: /^\S+@\S+\.\S+$/,
                                      message: "Enter a valid email address",
                                    },
                                  })}
                                />
                              </div>
                              {errorsLogin.email && (
                                <p className={Cx.errorMessage}>{errorsLogin.email.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <IoLockClosedOutline />
                                </div>
                                <input
                                  type={showPassword ? "text" : "password"}
                                  id="password"
                                  placeholder="Password"
                                  {...registerLogin("password", {
                                    required: "Password is required",
                                    minLength: {
                                      value: 8,
                                      message: "Password must be at least 8 characters long",
                                    },
                                    maxLength: {
                                      value: 16,
                                      message: "Password must not exceed 16 characters",
                                    },
                                  })}
                                />
                                <FontAwesomeIcon
                                  icon={showPassword ? faEyeSlash : faEye}
                                  className={Cx.toggleIcon}
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              </div>
                              {errorsLogin.password && (
                                <p className={Cx.errorMessage}>{errorsLogin.password.message}</p>
                              )}
                            </div>
                            <div className={`${Cx.forgetline} ${Cx.mainheading}`}>
                              <Link to="/forget-password" className={Cx.linkbtn}>
                                Forgot Password?
                              </Link>
                            </div>
                            <div className={Cx.mainheading}>
                              <button type="submit" className={Cx.submitButton2}>
                                Sign in
                              </button>
                            </div>

                            <div className={Cx.applegoogel}>
                              {/*  <p className={Cx.signtext}>Or Sign Up With</p> */}
                              <div className={Cx.iocnsareatapple}>
                                <button className={Cx.iconpoint} onClick={handelAppleSingin}>
                                  <div className={Cx.imagelogo}>
                                    <img src={Applelogo2} alt="logo" />
                                  </div>
                                  <p>Sign In With Apple</p>
                                </button>
                              </div>
                              <div className={Cx.iocnsareagoogel}>
                                <button className={Cx.iconpoint} onClick={handleLoginGoogle}>
                                  <div className={Cx.imagelogo}>
                                    <img src={Googlelogo2} alt="logo" />
                                  </div>
                                  <p>Sign In With Google</p>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className={Cx.signuploginform}>
                          <form onSubmit={handleSubmit(onSubmit)} className={Cx.logingform}>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <HiOutlineUser />
                                </div>

                                <input
                                  type="text"
                                  placeholder="Full Name"
                                  {...register("full_name", {
                                    required: "Full Name is required",
                                  })}
                                />
                              </div>
                              {errors.full_name && (
                                <p className={Cx.errorMessage}>{errors.full_name.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <HiOutlineBuildingOffice2 />
                                </div>
                                <input
                                  type="text"
                                  placeholder="Company Name"
                                  {...register("company_name", {
                                    required: "Company Name is required",
                                  })}
                                />
                              </div>
                              {errors.company_name && (
                                <p className={Cx.errorMessage}>{errors.company_name.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <IoMailOutline />
                                </div>

                                <input
                                  type="email"
                                  placeholder="Email"
                                  {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value: /^[\w.-]+@([\w-]+\.)+\w{2,4}$/,
                                      message: "Invalid email address",
                                    },
                                  })}
                                />
                              </div>
                              {errors.email && (
                                <p className={Cx.errorMessage}>{errors.email.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <MdOutlineLocalPhone />
                                </div>

                                <input
                                  type="tel"
                                  placeholder="Mobile Number"
                                  {...register("mobile_no", {
                                    required: "Mobile number is required",
                                  })}
                                />
                              </div>
                              {errors.mobile_no && (
                                <p className={Cx.errorMessage}>{errors.mobile_no.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <LiaIndustrySolid />
                                </div>

                                <input
                                  type="text"
                                  placeholder="Industry Type"
                                  {...register("industry", {
                                    required: "Industry is required",
                                  })}
                                />
                              </div>
                              {errors.industry && (
                                <p className={Cx.errorMessage}>{errors.industry.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <IoLockClosedOutline />
                                </div>

                                <input
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Password"
                                  {...register("password", {
                                    required: "Password is required",
                                    minLength: {
                                      value: 8,
                                      message: "Password must be at least 8 characters",
                                    },
                                  })}
                                />
                                <FontAwesomeIcon
                                  icon={showPassword ? faEyeSlash : faEye}
                                  className={Cx.toggleIcon}
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              </div>
                              {errors.password && (
                                <p className={Cx.errorMessage}>{errors.password.message}</p>
                              )}
                            </div>
                            <div className={Cx.formGroup}>
                              <div className={Cx.inputGroup}>
                                <div className={Cx.icon}>
                                  <IoLockClosedOutline />
                                </div>
                                <input
                                  type={showConfirmPassword ? "text" : "password"}
                                  placeholder="Confirm Password"
                                  {...register("password2", {
                                    required: "Please confirm your password",
                                    validate: (value) =>
                                      value === password || "Passwords do not match",
                                  })}
                                />
                                <FontAwesomeIcon
                                  icon={showConfirmPassword ? faEyeSlash : faEye}
                                  className={Cx.toggleIcon}
                                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                />
                              </div>
                              {errors.password2 && (
                                <p className={Cx.errorMessage}>{errors.password2.message}</p>
                              )}
                            </div>
                            <div className={Cx.mainheadingpp}>
                              <button type="submit" className={Cx.submitButton2} disabled={loading}>
                                {loading ? "Signing Up..." : "Sign Up"}
                              </button>
                            </div>

                            <div className={Cx.applegoogel}>
                              <p className={Cx.signtext}>Or Sign Up With</p>
                              <div className={Cx.iocnsareatapple}>
                                <button className={Cx.iconpoint} onClick={handelAppleSingup}>
                                  <div className={Cx.imagelogo}>
                                    <img src={Applelogo2} alt="logo" />
                                  </div>
                                  <p>Sign Up With Apple</p>
                                </button>
                              </div>
                              <div className={Cx.iocnsareagoogel}>
                                <button className={Cx.iconpoint} onClick={handleClick}>
                                  <div className={Cx.imagelogo}>
                                    <img src={Googlelogo2} alt="logo" />
                                  </div>
                                  <p>Sign Up With Google</p>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Signuplogin;
