// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";
import DefaultFooter from "examples/Footers/DefaultFooter";

/* import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import bgImage from "assets/images/bg-about-us.jpg";
import MKButton from "components/MKButton";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter"; 
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";*/

function PrivacyPolicy() {
  return (
    <>
      {/*     <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/agfinance-hub-react",
          label: "free download",
          color: "default",
        }}
      /> */}
      <Headerhome />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              className={Cx.main_heading}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <h1>
                <b>AG FINANCE AND TECHNOLOGY GROUP LTD</b>
              </h1>
            </MKTypography>
            <MKTypography className={Cx.main_heading}>
              <h1>PRIVACY POLICY</h1>
              <h2>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
              <p>for www.agfinancehub.com and the mobile application ‘AG Finance Hub’</p>
            </MKTypography>
            {/*  <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              create account
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 8 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Introduction</h3>

          <ul>
            <li>
              AG Finance and Technology Group Ltd (hereinafter referred to as &quot;we,&quot;
              &quot;our,&quot; or &quot;us&quot;) is deeply committed to protecting the privacy and
              confidentiality of all individuals and businesses who interact with our mobile
              application (&quot;app&quot;), website, and associated services. This Privacy Policy
              describes the types of personal data we collect, the purposes for which such data is
              processed, the legal grounds underpinning our data processing activities, and the
              rights available to you as a data subject under applicable laws.
            </li>

            <li>
              This Privacy Policy is drafted in compliance with the UK General Data Protection
              Regulation (UK GDPR), the Data Protection Act 2018 (DPA 2018), and other applicable
              data protection laws in the United Kingdom. It serves to outline our obligations to
              protect your personal data and ensure transparency in our data processing practices.
            </li>

            <li>
              By accessing or using our app, website, or services, you confirm that you have read
              and understood this Privacy Policy. Your continued use of our app or services
              signifies your acceptance of the terms and data processing practices described herein.
            </li>

            <li>
              This Privacy Policy should be read alongside any other privacy notices or specific
              data processing information provided at the time of collecting your personal data.
              Such notices supplement this Privacy Policy and provide context for specific data
              processing activities.
            </li>

            <li>
              While our app and website may include links to third-party websites, plug-ins, or
              applications, we do not control these external entities and are not responsible for
              their privacy practices. We strongly encourage you to review the privacy policies of
              any third-party platforms you engage with.
            </li>
          </ul>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Scope of Application</h3>
          <ul>
            <li>
              This Privacy Policy applies to all data collected, processed, and stored by AG Finance
              and Technology Group Ltd through its app, website, and associated communication
              channels.
            </li>
            <li>
              It governs how we handle data during interactions with individual users, corporate
              entities, strategic partners, brokers, and lenders.
            </li>
            <li>
              The scope of this Privacy Policy extends to data processed directly through user
              interactions, as well as data shared with us by third parties or generated through
              automated technologies.
            </li>
          </ul>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3> Data Collection</h3>
          <p>
            We collect and process various categories of personal data, depending on the nature of
            your interaction with our app, website, or services. The categories of data include, but
            are not limited to:
          </p>
          <ul>
            <li>
              <b>Personal Identification Data:</b> This includes your full name, residential or
              business address, email address, phone number, date of birth, and other identifiers
              necessary to verify your identity and facilitate services.
            </li>
            <li>
              <strong>Financial Data:</strong> This includes bank statements, VAT returns,
              management accounts, filed accounts, corporate tax documentation, transactional
              records, and any other financial data required for evaluating eligibility for
              financing or corporate tax relief services.
            </li>
            <li>
              <strong>Usage Data:</strong> We collect data on your interaction with our app or
              website, including IP addresses, geographical location, device information, browser
              type, operating system, referral source, session duration, navigation paths, and app
              usage patterns.
            </li>
            <li>
              <strong>Technical Data:</strong> This pertains to device-specific details, such as the
              unique identifiers of your mobile device, operating system version, and diagnostic
              information related to app performance and usage.
            </li>
            <li>
              <strong>Communication Data:</strong> This includes content from communications sent to
              us via email, in-app messaging, telephone, or other channels, along with metadata
              generated during these interactions.
            </li>
            <li>
              <strong>Marketing Data:</strong> This includes your preferences regarding marketing
              communications, your engagement with promotional content, and responses to campaigns.
            </li>
          </ul>
          <p>We may also collect data from third-party sources, including:</p>
          <ul>
            <li>
              <strong>Financial institutions or credit reference agencies:</strong> Providing
              supplemental data to support financing solutions.
            </li>
            <li>
              <strong>Publicly available databases:</strong> To verify user identity and eligibility
              for services.
            </li>
            <li>
              <strong>Analytics providers:</strong> Such as Google Analytics, to enhance app
              functionality and user experience.
            </li>
          </ul>
          <p>
            Users are responsible for ensuring that all data provided to us is accurate, complete,
            and up-to-date. Misrepresentation or provision of inaccurate data may result in delays
            or denial of services.
          </p>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Purpose and Legal Basis for Data Processing</h3>

          <p>
            Personal data is processed for specific purposes, each of which is supported by a lawful
            basis as required under UK GDPR.
          </p>

          <ul>
            <li>
              <strong>Provision of Services:</strong> Your personal and financial data is processed
              to deliver services such as business financing facilitation, corporate tax relief
              solutions, and related offerings. This may include sharing data with strategic
              partners, brokers, or lenders. The legal basis for this processing is the performance
              of a contract or steps taken at your request prior to entering into a contract.
            </li>
            <li>
              <strong>Compliance with Legal Obligations:</strong> Certain processing activities,
              such as maintaining records for tax compliance, fulfilling statutory reporting
              requirements, and adhering to anti-money laundering (AML) regulations, are carried out
              to comply with legal obligations.
            </li>
            <li>
              <strong>Legitimate Interests:</strong> We process data for legitimate business
              interests, including improving app functionality, responding to user inquiries, and
              tailoring services to user needs. Such processing is balanced against your fundamental
              rights and freedoms.
            </li>
            <li>
              <strong>Consent:</strong> For activities requiring your explicit consent, such as
              sending promotional communications or enabling non-essential cookies, we process data
              only upon receiving your approval. You retain the right to withdraw consent at any
              time without affecting prior lawful processing.
            </li>
            <li>
              <strong>Automated Decision-Making and Profiling:</strong> We do not engage in
              automated decision-making processes that produce legal or significant effects.
              Profiling activities are subject to human review to ensure fairness and transparency.
            </li>
            <li>
              <strong>Communication and Notifications:</strong> Personal data, including your mobile
              number, may be processed to provide notifications, updates, and communications
              regarding our services. This processing is based on your explicit consent when you
              provide your mobile number. Users may withdraw consent at any time by contacting us or
              using the opt-out mechanism provided in such communications.
            </li>
          </ul>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Sharing of Personal Data</h3>

          <p>
            Personal data may be shared with selected third parties in specific circumstances,
            including:
          </p>

          <ul>
            <li>
              <strong>Strategic Partners and Lenders:</strong> Your financial data may be shared
              with brokers, lenders, or financial institutions to facilitate tailored financing
              options. These entities are contractually bound to adhere to GDPR or equivalent
              standards.
            </li>
            <li>
              <strong>Service Providers:</strong> Third-party providers engaged to support our app
              and services (e.g., cloud hosting, analytics, or customer support) may process data on
              our behalf under strict confidentiality agreements.
            </li>
            <li>
              <strong>Regulatory Authorities:</strong> Data may be disclosed to regulatory bodies,
              law enforcement agencies, or courts to comply with legal obligations or valid legal
              requests.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of
              assets, your personal data may be transferred to the new business entity under the
              same data protection commitments.
            </li>
          </ul>

          <p>
            We do not sell personal data to third parties or share it for their independent
            marketing purposes.
          </p>

          <p>
            <strong>Non-Circumvention Obligations:</strong> By using our app, you agree to the
            non-circumvention obligations detailed in our Terms of Use, which govern interactions
            with disclosed lenders and partners.
          </p>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>International Data Transfers</h3>
          <p>
            In some cases, personal data may be transferred outside the UK or EEA to facilitate
            service delivery, collaboration with partners, or engagement with third-party service
            providers.
          </p>

          <p>Such transfers are subject to safeguards, including:</p>

          <ul>
            <li>
              <strong>Data transfers to jurisdictions:</strong> Data transfers to jurisdictions
              deemed to provide adequate protection by the European Commission.
            </li>
            <li>
              <strong>Standard Contractual Clauses (SCCs):</strong> Implementation of legally
              binding Standard Contractual Clauses (SCCs) approved by the European Commission.
            </li>
          </ul>

          <p>
            For additional details regarding international transfers, please contact our Data
            Protection Officer.
          </p>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Data Retention</h3>
          <p>
            Personal data is retained for as long as necessary to fulfill the purposes for which it
            was collected, comply with legal obligations, or resolve disputes. Specific retention
            periods include:
          </p>

          <ul>
            <li>
              <strong>Financial data:</strong> Retained for up to ten years to meet regulatory and
              statutory requirements.
            </li>
            <li>
              <strong>Communication data:</strong> Retained for two years unless part of an ongoing
              agreement.
            </li>
            <li>
              <strong>Marketing data:</strong> Retained until you opt out or withdraw consent.
            </li>
          </ul>
          <p>
            At the end of retention periods, data is securely deleted or anonymized to prevent
            re-identification
          </p>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Your Rights</h3>
          <p>
            As a data subject under UK GDPR, you have the following rights in relation to your
            personal data processed by us:
          </p>
          <ul>
            <li>
              <strong>Right to Access:</strong> You have the right to request access to your
              personal data and obtain confirmation as to whether we process your data. Upon
              request, we will provide a copy of your data and details of the processing activities,
              including purposes, categories of data processed, and third parties with whom the data
              has been shared.
            </li>
            <li>
              <strong>Right to Rectification:</strong> If any of your personal data is inaccurate or
              incomplete, you have the right to request corrections or updates. We will take
              reasonable steps to verify the accuracy of the data and make necessary amendments.
            </li>
            <li>
              <strong>Right to Erasure (Right to Be Forgotten):</strong> You may request the
              deletion of your personal data when it is no longer necessary for the purposes for
              which it was collected, if you withdraw your consent, or if the processing is
              unlawful. However, we may retain data for legal or compliance reasons.
            </li>
            <li>
              <strong>Right to Restriction of Processing:</strong> You can request the restriction
              of data processing under certain circumstances, such as when you contest the accuracy
              of the data or object to processing while we evaluate your request.
            </li>
            <li>
              <strong>Right to Data Portability:</strong> You have the right to receive your
              personal data in a structured, machine-readable format and to transfer the data to
              another controller where technically feasible.
            </li>
            <li>
              <strong>Right to Object:</strong> You may object to the processing of your personal
              data for direct marketing purposes or where processing is based on legitimate
              interests. We will cease processing unless we demonstrate compelling legitimate
              grounds.
            </li>
            <li>
              <strong>Right to Withdraw Consent:</strong> Where processing is based on your consent,
              you may withdraw that consent at any time. Withdrawal will not affect the lawfulness
              of prior processing.
            </li>
            <li>
              <strong>Right to Lodge a Complaint:</strong> If you believe your rights have been
              violated, you have the right to lodge a complaint with the UK Information
              Commissioner’s Office (ICO) or another relevant supervisory authority.
            </li>
          </ul>
          <p>
            To exercise your rights, contact our Data Protection Officer using the contact details
            provided in Section 13. Requests will be addressed within one month, with possible
            extensions under GDPR provisions.
          </p>
        </MKTypography>
        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Cookies and Tracking Technologies</h3>
          <p>
            Our app and website use cookies and similar tracking technologies to improve
            functionality, personalize your experience, and analyze usage patterns.
          </p>
          <h4>Types of Cookies and Technologies:</h4>
          <ul>
            <li>
              <strong>Essential Cookies:</strong> Necessary for the operation of our app and
              website.
            </li>
            <li>
              <strong>Analytics Cookies:</strong> Used to gather insights into app and website
              performance.
            </li>
            <li>
              <strong>Preference Cookies:</strong> Store user settings and preferences.
            </li>
            <li>
              <strong>Marketing Cookies:</strong> Facilitate personalized advertising and
              promotional content.
            </li>
          </ul>
        </MKTypography>

        <MKTypography variant="body1" className={Cx.heading_textarea}>
          <h3>Data Security</h3>
          <p>
            We implement stringent technical and organizational measures to ensure the security of
            your personal data. This includes encryption, secure storage, access controls, and
            regular security audits.
          </p>
          <p>
            In the event of a data breach, affected users will be notified within 72 hours of our
            awareness, as mandated by UK GDPR. Notifications will outline the nature of the breach,
            the data involved, and recommended actions.
          </p>
          <p>
            While we take comprehensive steps to safeguard your data, users are encouraged to employ
            best practices for account security, such as using strong passwords and safeguarding
            login credentials.
          </p>

          <h3>Amendments to the Privacy Policy</h3>
          <p>
            This Privacy Policy may be updated periodically to reflect changes in our practices,
            legal requirements, or technological advancements.
          </p>
          <p>
            Significant updates will be communicated via email, in-app notifications, or other
            appropriate channels at least 30 days before they take effect. Continued use of our app
            or services after such updates constitutes acceptance of the revised policy.
          </p>

          <h3>Legal Jurisdiction</h3>
          <p>
            This Privacy Policy is governed by and construed in accordance with the laws of England
            and Wales.
          </p>
          <p>
            Any disputes arising from or in connection with this Privacy Policy shall be subject to
            the exclusive jurisdiction of the courts of England and Wales.
          </p>

          <h3>Contact Information</h3>
          <p>
            If you have questions, concerns, or requests regarding this Privacy Policy or your data
            rights, please contact: <a href="mailto:info@agfinancehub.com">info@agfinancehub.com</a>
          </p>
          <p>
            AG Finance and Technology Group Ltd
            <br />
            71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
          </p>
          <p>
            Our application for the UK Information Commissioner’s Office (ICO) as a data controller
            is pending application reference number Your application number is C1613559.
          </p>
        </MKTypography>
      </Card>

      <MKBox pt={6} px={0} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
