import React, { useRef, useEffect, useState } from "react";
import MKBox from "components/MKBox";
import videoSource from "assets/images/AGFinancepromo.mp4";
import videoFinance from "assets/images/AGFinanceHub.mp4";
import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Cx from "./page.module.scss";
import Headerhome from "layouts/hearderhome/Headerhome";
import Name1 from "../../assets/images/blogs/IndependenetArticle.png";
import Name2 from "../../assets/images/blogs/IndependentWeb.png";
import Name3 from "../../assets/images/blogs/InflationArticle.png";

import Blog1 from "../../assets/images/blogs/reserch.jpg";
import Blog2 from "../../assets/images/blogs/autumnbudget.png";
import Blog3 from "../../assets/images/blogs/corporateblog.png";
import Bigimage1 from "../../assets/images/shapes/loginimage.svg";
import Bigimage2 from "../../assets/images/blogs/dashboardfigma.png";
import Bigimage3 from "../../assets/images/shapes/dashboard.svg";
/* import ImageOne from "../../assets/images/shapes/section6th1st.webp";
import Imagetwo from "../../assets/images/sliderThree.jpg";
import Imagethree from "../../assets/images/shapes/section6th2nd.webp";
 */
import Coverimage from "../../assets/images/shapes/ClientVideoCover.png";
import AiProduct1 from "../../assets/images/products/product-owl1.jpg";
import AiProduct2 from "../../assets/images/products/product-owl2.jpg";
//import AiProduct3 from "../../assets/images/products/product-owl3.jpg";
//import AiProduct4 from "../../assets/images/products/product-3-min.jpg";
import Aplication from "../../assets/images/shapes/apllicationsstatuss.png";
/* ---owl-- */
import $ from "jquery";
window.$ = window.jQuery = $;
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
//import Newslogo from "../../assets/images/indipandennews.svg";
import { Link, useLocation } from "react-router-dom";
/* --- */
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons"; */

//import Header from "layouts/hearder";
/* import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes"; */
//import Container from "@mui/material/Container";
//import Card from "@mui/material/Card";
//import BuiltByDevelopers from "pages/components/BuiltByDevelopers";
//import Yourjourney from "pages/sections/Yourjourney";

const Home = () => {
  const location = useLocation();
  let code = location?.search?.split("?code=")[1]?.split("&")[0];
  let userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("code", code);
        formData.append("user", userId);
        const response = await fetch("https://api.agfinancehub.com/api/xero/", {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          console.log("API Response:", data);
        } else {
          console.error("API Request Failed:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const options = {
    items: 1,
    //loop: true,
    nav: true,
    dots: true,
    dotsClass: Cx.customDots,
    autoplay: true,
    autoplayTimeout: 1000,
    navText: [false],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      1024: { items: 2 },
    },
  };
  /* ======Nwes paper area====== */
  const options1 = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    dotsClass: Cx.customDots,
    autoplay: true,
    autoplayTimeout: 1000,
    navText: [false],
    responsive: {
      0: { items: 1 },
      768: { items: 1 },
      1024: { items: 1 },
    },
  };
  /*   const videoRef = useRef(null);
  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }; */

  /* const videoRef = useRef(null);
  const homesection9thRef = useRef(null);
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };
  useEffect(() => {
    const video = videoRef.current;
    const homesection9th = homesection9thRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (video) {
              video.play();
            }
          } else {
            if (video) {
              video.pause();
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    if (homesection9th) {
      observer.observe(homesection9th);
    }

    return () => {
      if (homesection9th) {
        observer.unobserve(homesection9th);
      }
    };
  }, []); */

  const videoRef = useRef(null);
  const homesection9thRef = useRef(null);

  // Track play/pause state
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause();
        setIsPlaying(false);
      } else {
        video
          .play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((err) => {
            console.error("Error trying to play the video:", err);
          });
      }
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    const homesection9th = homesection9thRef.current;

    /*   const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Section is in view, but do not autoplay the video
            // Just make sure we don't trigger autoplay here
            console.log("Section is in view, but video should not autoplay yet");
          } else {
            // Section is out of view
            if (video && isPlaying) {
              video.pause();
              setIsPlaying(false);
            }
          }
        });
      },
      { threshold: 0.1 }
    ); */

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (isPlaying && video) {
              // If the video was played before, autoplay it
              video.play().catch((err) => {
                console.error("Error trying to resume the video:", err);
              });
            }
          } else {
            if (video && isPlaying) {
              video.pause();
              setIsPlaying(false); // Pause the video when it is out of view
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (homesection9th) {
      observer.observe(homesection9th);
    }

    return () => {
      if (homesection9th) {
        observer.unobserve(homesection9th);
      }
    };
  }, [isPlaying]);

  return (
    <>
      {<Headerhome />}
      <section className={Cx.homesection1st}>
        <div className="container px-0">
          <div className="row">
            <div className="col-12">
              <div className={Cx.yourfinance}>
                <h1>Your Financial Journey</h1>
                <h2>Our Expertise.</h2>
                <div className={Cx.pergraph}>
                  <p>
                    &ldquo;Free access to the largest network of lenders in the UK plus award
                    winning corporate tax services. For SMEs and multinationals, for sole traders
                    and partnerships, for accountants and business consultants—for your
                    aspirations.&rdquo;
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className={Cx.searchContainer}>
                    <input
                      type="text"
                      placeholder="Enter work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief.</button>
                  </div>
                  <div className={Cx.searchphoneview}>
                    <input
                      type="text"
                      placeholder="Enter work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief.</button>
                  </div>
                </div>
                <small className={Cx.smallText}>
                  Recognised and recommended by The Independent as a company with ‘stellar’<br></br>
                  services and products to ‘improve your business’
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection1stphone}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={Cx.yourfinance}>
                <h1>Your Financial Journey</h1>
                <h2>Our Expertise.</h2>
                <div className={Cx.pergraph}>
                  <p>
                    &ldquo;Free access to the largest network of lenders in the UK plus award
                    winning corporate tax services. For SMEs and multinationals, for sole traders
                    and partnerships, for accountants and business consultants—for your
                    aspirations.&rdquo;
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className={Cx.searchContainer}>
                    <input
                      type="text"
                      placeholder="Enter work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief.</button>
                  </div>
                  <div className={Cx.searchphoneview}>
                    <input
                      type="text"
                      placeholder="Enter work email for a complimentary discovery call"
                      className={Cx.searchInput}
                    />
                    <button className={Cx.searchButton}>corporate tax relief.</button>
                  </div>
                </div>
                <small className={Cx.smallText}>
                  Recognised and recommended by The Independent as a company with ‘stellar’<br></br>
                  services and products to ‘improve your business’
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection8th}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.bannerimage}>
            <div className="container">
              <div className="row">
                <div className="col-lg-11 col-md-12  mx-auto">
                  <div className={Cx.videowithimage}>
                    <MKBox className={Cx.mainvedio}>
                      <video autoPlay muted loop className={Cx.jomevideoarea}>
                        <source src={videoSource} type="video/mp4" />
                      </video>
                    </MKBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={Cx.homesection10th}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.bannercards}>
            <div className="container">
              <div className="row">
                <div className="col-9 mx-auto mt-5">
                  {/*    <div className={Cx.peragraph}>
                      <p>We’ve helped companies like yours win clients like these:</p>
                      <p>
                        <span>
                          ‘AG Finance Hub, created by the AG Finance & Technology Group, is the
                          world’s first and only tech-led finance and tax solutions service. Founded
                          by the Gera brothers, the company offers access to the UK’s largest
                          network of lenders and award-winning tax services for SMEs and
                          multinationals, all at no cost. Its network has provided just under £1bn
                          in funding to UK businesses and has secured substantial corporate tax
                          relief for eligible UK companies. The AG Finance & Technology Group is
                          pioneering how UK businesses unlock tax relief and business finance. Your
                          needs, their expertise, one destination — AG Finance Hub.’ The
                          Independent, <img className={Cx.newslogo} src={Newslogo} alt="logonews" />
                        </span>
                      </p>
                      
                    </div> */}
                  <div className={`${Cx.nameimagearea} mb-1`}>
                    <div className="row  mx-auto">
                      <OwlCarousel
                        options={options1}
                        style={{
                          zIndex: "auto",
                        }}
                      >
                        <div className={Cx.carouselItem}>
                          <div className={Cx.imgpoint}>
                            <img src={Name1} alt="Logo" />
                          </div>
                        </div>
                        <div className={Cx.carouselItem}>
                          <div className={Cx.imgpoint}>
                            <img src={Name2} alt="Logo" />
                          </div>
                        </div>
                        <div className={Cx.carouselItem}>
                          <div className={Cx.imgpoint}>
                            <img src={Name3} alt="Logo" />
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection2nd}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.bannertopimage}>
            <div className="container ">
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
          <div className={Cx.thereimagepart}>
            <div className="container ">
              <div className={Cx.therepartpadding}>
                <div className="row">
                  <div className="col-12 text-center  mb-4">
                    <h2>Blogs</h2>
                  </div>
                  <div className="col-lg-4  col-md-6 mx-auto">
                    <div className={Cx.blogscard}>
                      <Link to="/blogone">
                        <div className={Cx.imgpointcontent}>
                          <img src={Blog1} alt="Logo" />
                        </div>
                        <p>Research and Development April 2024</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mx-auto">
                    <div className={Cx.blogscard}>
                      <div className={Cx.imgpointcontent}>
                        <img src={Blog2} alt="Logo" />
                      </div>
                      <p>Autumn Budget: Understanding the UK Corporate Tax Roadmap Ahead</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mx-auto">
                    <div className={Cx.blogscard}>
                      <div className={Cx.imgpointcontent}>
                        <img src={Blog3} alt="Logo" />
                      </div>
                      <p>Corporate Reconstructions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection3rd}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.threetopimage}>
            <div className="container">
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <div className={Cx.hedingbig}>
                <p>Get better results</p>
                <h2>Quick, Easy, Reliable, Free.</h2>
              </div>
            </div>
          </div>
          <div className={Cx.padingstopbottom}>
            <div className="row">
              <div className="col-md-12 col-lg-6  order-md-2 order-2 order-lg-1 ">
                <div className={Cx.imgpointbig}>
                  <img src={Bigimage1} alt="banner" />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 order-md-1 order-1  order-lg-2 mb-5">
                <div className={Cx.textpartbig}>
                  <h2>01</h2>
                  <h3>Login to AG Finance Hub</h3>
                  <p>
                    The AG Finance Hub is the only platform and mobile app that allows UK businesses
                    to unlock corporate tax relief from start to finish, while also providing UK
                    businesses free access to the UK’s largest network of over 140 lenders for
                    bespoke business financing solutions ranging from £10,000 to £20,000,000.
                  </p>
                  <p>
                    <span>
                      Our network has provided just under £1bn in funding to UK businesses
                    </span>
                  </p>

                  <div className="mt-3">
                    <button type="submit" className={Cx.submitButton2}>
                      Book a free Discovery Call
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={Cx.padingstopbottom}>
            <div className="row mt-5 mb-5">
              <div className="col-md-12 col-lg-6  mb-5">
                <div className={Cx.textpartbig}>
                  <h2>02</h2>
                  <h3>Select the service you are looking for.</h3>
                  <p>
                    Tell us if you are looking for business finance or corporate tax relief
                    services. Then, fill out a short discovery questionnaire and book your free
                    discovery call. In the meantime, our team will assess the information you
                    provide to ensure you we can give you the help you need.
                  </p>
                  <p>
                    <span>
                      Free access to the UK’s largest network of over 140 lenders for bespoke
                      business financing solutions ranging from £10,000 to £20,000,000, and, award
                      winning corporate tax services
                    </span>
                  </p>
                  <div className="mt-3">
                    <button type="submit" className={Cx.submitButton2}>
                      Book a free Discovery Call
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 ">
                <div className={Cx.imgpointbig}>
                  <img src={Bigimage2} alt="banner" />
                </div>
              </div>
            </div>
          </div>

          <div className={Cx.padingstopbottom}>
            <div className="row mt-5 mb-5">
              <div className="col-md-12 col-lg-6 order-md-2 order-2 order-lg-1 mb-5">
                <div className={Cx.imgpointbig}>
                  <img src={Bigimage3} alt="banner" />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 order-md-1 order-1 order-lg-2 mb-5">
                <div className={Cx.textpartbig}>
                  <h2>03</h2>
                  <h3>Manage everything through the dashboard and receive live updates.</h3>
                  <p>
                    Whether you&apos;re looking for corporate tax services or business finance
                    solutions, our web platform and mobile app make managing everything simple and
                    efficient. Through our dashboard, you can upload financial documents, schedule
                    calls with our team, and complete technical analyses for R&D claims - all in one
                    place. Plus, you&apos;ll receive live updates to track the status of your work.
                  </p>
                  <p>
                    <span>
                      AG Finance Hub is the UK’s first tech-driven, human-led solution for corporate
                      tax and business finance
                    </span>
                  </p>

                  <div className="mt-3">
                    <button type="submit" className={Cx.submitButton2}>
                      Book a free Discovery Call
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.threebottomimage}>
            <div className={Cx.paddingarea}></div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection4th}>
        <div className="container ">
          <div className={Cx.rightleftsides}>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className={Cx.leftsidetext}>
                  {/* <p>No lead, no fee</p> */}
                  <h2>With AG Finance Hub, you only pay when we deliver results.</h2>
                  <div className={Cx.peraghraph}>
                    <p>
                      Our platform and mobile app are completely free to use. For business finance
                      solutions, there are no fees, hidden costs, or charges to get you the solution
                      you need—guaranteed. For our corporate tax relief services, you only pay when
                      a tangible tax benefit is delivered to your company. From start to finish, the
                      entire process is transparent and free of hidden costs, ensuring you receive
                      value before any payment is required.
                    </p>
                    {/*  <p>
                      You have full control of your budget and there&rdquo;s no minimum commitment,
                      so you can cancel any time.
                    </p> */}
                  </div>
                  <div className={Cx.unlockthepotential}>
                    <button type="submit" className={Cx.submitButton2}>
                      Unlock the potential of business finance and corporate tax relief.
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 ">
                <div className={Cx.rightsidetext}>
                  <div className={Cx.imgpointbig}>
                    <img src={Aplication} alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Cx.homesection9th} ref={homesection9thRef}>
        <div className="container-fluid px-0 mx-0">
          <div className={Cx.videosection}>
            <div className="container">
              <div className="row m-auto">
                <div className="col-8 m-auto">
                  <div className={Cx.videopart}>
                    <MKBox
                      width="100%"
                      sx={{
                        display: "grid",
                        placeItems: "center",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <video
                        onClick={togglePlayPause}
                        ref={videoRef}
                        autoPlay
                        // controls
                        poster={Coverimage}
                        loop
                        className={`${Cx.jomevideoarea} fadeInUp`}
                      >
                        <source src={videoFinance} type="video/mp4" />
                        {/*  Your browser does not support the video tag. */}
                      </video>
                      {/*   <button onClick={togglePlayPause} className={Cx.playPauseButton}>
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                  </button> */}
                    </MKBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-0 mx-0">
            <div className={Cx.forbottomimage}>
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
          {}
        </div>
      </section>

      <section className={Cx.homesection7th}>
        <div className="container-fluid px-0 mx-0 fadeInUp">
          {/*   <div className={Cx.bannertopimage}>
            <div className="container-fluid px-0 mx-0">
              <div className={Cx.paddingarea}></div>
            </div>
          </div> */}
          <div className={Cx.thereimagepart}>
            <div className="container ">
              <div className={Cx.therepartpadding}>
                <div className={Cx.headerpoints}>
                  <h2>Ag Finance Hub</h2>
                  <h4>Articles</h4>
                </div>
                <div className="row mx-0 px-0">
                  <OwlCarousel
                    options={options}
                    style={{
                      zIndex: "auto",
                    }}
                  >
                    <div className={Cx.carouselItem}>
                      <div className={Cx.imgpointcontent}>
                        <img src={AiProduct1} alt="Financial Insights" />
                      </div>
                      <p>Empowering your financial journey with insights that matter.</p>
                    </div>
                    <div className={Cx.carouselItem}>
                      <div className={Cx.imgpointcontent}>
                        <img src={AiProduct2} alt="Financial Strategy" />
                      </div>
                      <p>
                        UK inflation drops to 1.7% - increasing likelihood of further interest rate
                        cuts as inflation dips below BoE target for the first time since 2021.
                      </p>
                    </div>
                    {/*   <div className={Cx.carouselItem}>
                      <div className={Cx.imgpointcontent}>
                        <img src={AiProduct3} alt="Wealth Building" />
                      </div>
                      <p>Is HMRC’s view on R&D always right?</p>
                    </div> */}
                    {/*  <div className={Cx.carouselItem}>
                      <div className={Cx.imgpointcontent}>
                        <img src={AiProduct4} alt="Wealth Building" />
                      </div>
                      <p>Corporate Reconstructions</p>
                    </div> */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-0 mx-0 ">
            <div className={Cx.bannertopimage}>
              <div className={Cx.paddingarea}></div>
            </div>
          </div>
        </div>
      </section>

      <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Home;
